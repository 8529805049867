@import "src/styles/common";

.type-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 52px;
  width: 100%;
}

.type-wrap {
  @include flex-column-vertical-center;
  gap: 24px;
  margin-top: 48px;
  width: 100%;
}

.type-img-wrap {
  @include flex-column-vertical-center;
  gap: 12px;

  & > span { @include set-title-2; }
}

.type-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: $primary100;
  @include flex-center;

  & > svg { color: $primary500; }
}

.type-btn-wrap {
  @include flex-column;
  gap: 12px;
  width: 100%;
  & > button { gap: 8px; }
}

.user-type-info {
  background-color: #f8f8f8;
  border-radius: 8px;
  width: 100%;
  padding: 20px 24px;
  @include flex-column;
  gap: 4px;

  & > h3 { @include set-subtitle-2; }
  & > p {
    @include set-body-2;
    color: #474747;
  }
}
