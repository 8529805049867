@import "src/styles/common";

.login {
  &__container {
    @include flex-column-vertical-center;
    gap: 20px;
    width: 100%;
    margin: auto;
    margin-top: 40px;
    padding: 0 20px;
  }

  &__content {
    width: 100%;
    margin-top: 56px;
    gap: 40px;
  }

  &__input-wrap {
    @include flex-column;
    gap: 20px;

    & + p {
      color: $danger;
      margin-top: 20px;
    }
  }

  &__btn-wrap {
    @include flex-column;
    gap: 12px;
    margin-top: 40px;
  }

  &__link-wrap {
    display: flex;
    gap: 16px;

    & > a {
      @include set-body-2;
      color: $black500;
    }
  }
}
