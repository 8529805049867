@import "src/styles/common";

.checkbox-item {
  display: flex;
  align-items: center;
  gap: 12px;

  & > label {
    @include set-subtitle-1;
    &.all { @include set-title-1; }
    & > em {
      color: $black300;
      margin-right: 2px;
      &.essential {
        color: $primary500;
      }
    }
  }
}

.checkbox-wrap {
  width: 28px;
  height: 28px;
  border: 1px solid $black100;
  border-radius: 50%;
  @include flex-center;
  cursor: pointer;

  & > input {
    appearance: none;
    margin: 0;
  }
  &.checked {
    background-color: $primary500;
    & > svg { color: white; }
  }
}
