@import "src/styles/common";

.login {
  &__container {
    @include flex-column-vertical-center;
    gap: 20px;
    width: 100%;
    margin: auto;
    margin-top: 40px;
    padding: 0 20px;
  }

  &__content {
    width: 100%;
    margin-top: 56px;
    gap: 40px;
  }

  &__line {
    width: 100%;
    border: 1px solid $black100;
    margin: 32px 0;
  }

  &__btn-wrap {
    @include flex-column;
    gap: 12px;
    margin-top: 40px;
  }
}