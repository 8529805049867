@import "src/styles/common";

.footer {
  &__container {
    width: 100%;
    padding: 30px;
    border: 1px solid $black100;
    background-color: $primary300;
  }

  &__content {
    @include flex-column;
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
    color: $white;

    h3 { @include set-title-2; }
    p { @include set-body-1; }
    
    &__term-wrap {
      @include set-body-1;
      font-weight: 600;

      & > span:first-child::after {
        content: "|";
        padding: 5px;
      }
    }

    @include mobile {
      h3 { @include set-subtitle-1; }
      p { @include set-body-2; }
      &__term-wrap {
        @include set-body-2;
        font-weight: 600;
      }
    }
  }
}