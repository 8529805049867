@import "src/styles/common";

.white-box {
  background-color: $white;
  padding: 32px;
  h3 { @include set-title-1; }
  .txt-title { @include set-subtitle-2; }
  .txt-subTitle {
    @include set-body-1;
  }
  .txt-sub { @include set-body-2; }
  .txt-gray { color: $black300; }

  @include mobile {
    padding: 28px 24px;
    h3 { @include set-title-2; }
    .txt-title { @include set-subtitle-3; }
    .txt-subTitle {
      @include set-body-2;
      white-space: pre;
    }
  }
}

.main-info { 
  &__container {
    width: 100%;
    
    @include tablet { display: none;  }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 400px;
    width: 100%;
    margin: 0 auto;

    @include tablet { max-width: none; }
  }

  &__title-wrap {
    display: grid;
    gap: 16px;

    & > h1 {
      @include set-headline-1;
      & span { color: $primary500; }
    }

    @include mobile {
      & > h1 { @include set-headline-2; }
    }
  }

  &__explain {
    border-radius: 24px;
    padding-bottom: 0;
    height: 556px;
    position: relative;
    @include tablet { height: 300px; }
  }

  &__img-wrap {
    position: absolute;
    bottom: -2px;
    @include tablet {
      right: 10px;
      & > svg {
        width: 200px;
        height: 214px;
      }
    }
    @include mobile {
      bottom: -3px;
      & > svg {
        width: 140px;
        height: 154px;
      }
    }
  }

  &__link-wrap {
    display: flex;
    flex-direction: column;
    gap: 20px;
    @include tablet { flex-direction: initial; }
    @include mobile { gap: 8px; }
  }

  &__banner { 
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    border-radius: 16px;
    padding: 20px 24px;
    width: 100%;
    cursor: pointer;
    @include mobile {
      justify-content: center;
      padding: 16px 20px;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 12px;
    @include mobile {
      gap: 8px;
      & + .icon-wrap { display: none; }
      & > svg {
        width: 28px;
        height: 28px;
      }
    }
  }
  &__link-text {
    display: grid;
    gap: 2px;
    @include mobile {
      & > .txt-sub { display: none; }
    }
  }
}