@import "src/styles/common";

.col-input-item-container {
  @include flex-column;
  gap: 12px;

  & > label {
    @include set-subtitle-1;
    color: $black500;
  }

  input {
    @include set-body-1;
    border: 1px solid $black100;
    border-radius: 8px;
    padding: 12px 16px;
    width: 100%;
  }

  @include mobile {
    input { @include set-body-2; }
  }
}

.col-input-item {
  @include flex-column;
  gap: 8px;
 
  &.error {
    input {
      border: 1px solid $danger;
    }
  }
}

.col-input-wrap {
  display: flex;
  gap: 12px;

  &.both {
    display: grid;
    grid-template-columns: 1fr 0.45fr;
  }
}
