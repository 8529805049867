@import "src/styles/common";

.cancel-user {
  &__content {
    & > h2 { @include set-headline-2;}

    .txt-bold {
      @include set-subtitle-1;
      color: $black500;
    }
    .txt-gray {
      @include set-body-1;
      color: $black300;
    }
    @include mobile {
      & > h2 {
        @include set-title-2;
        white-space: pre-line;
      }
      .txt-bold { @include set-subtitle-2; }
      .txt-gray { @include set-body-2; }
    }
  }
  &__text-box {
    border: 1px solid $black100;
    border-radius: 12px;
    padding: 32px;
    margin-top: 24px;
    @include flex-column;
    gap: 32px;
    @include mobile {
      padding: 24px;
      margin-top: 16px;
      gap: 20px;
    }
  }

  &__text-item {
    @include flex-column;
    gap: 12px;
  }

  &__text-box-wrap {
    @include flex-column;
    gap: 24px;
    margin-top: 40px;
    margin-bottom: 32px;
    & > h3 { @include set-title-1; }

    @include mobile {
      gap: 16px;
      margin-top: 28px;
      & > h3 { @include set-subtitle-1; }
    }
  }

  &__textarea-wrap {
    border: 1px solid $black100;
    border-radius: 12px;
    position: relative;
    width: 100%;
    height: 200px;
    padding: 0;

    & > textarea {
      border: none;
      border-radius: 12px;
      width: 100%;
      padding: 20px;
      height: 160px;
      resize: none;
      background: none;
      outline: none;

      &::placeholder {
        @include set-body-1;
        color: $black200;
      }
      &:focus { outline: none; }
    }

    & > .letter-num {
      @include set-body-2;
      position: absolute;
      right: 20px;
      bottom: 12px;
    }
  }
}
