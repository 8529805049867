@import "src/styles/common";

.mypage-user-info {
  &__content {
    @include flex-column;
    gap: 36px;
    width: 610px;

    @include tablet {
      width: 100%;
      gap: 32px;
    }
  }
}