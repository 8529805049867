@import "src/styles/common";

.mypage {
  &__content {
    @include flex-column;
    width: 100%;

    &__title-wrap {
      display: flex;
      align-items: center;
      border-bottom: 2px solid $black500;
      padding: 10px 0;
      margin-top: 18px;
      width: 100%;

      & > h3 { @include set-title-2; }
    }

    &__main-wrap {
      margin-top: 48px;
      @include mobile { margin-top: 24px; }
    }

    &__input-wrap {
      @include flex-column;
      gap: 20px;
    }

    &__btn-wrap {
      display: flex;
      gap: 12px;
      width: 100%;
    }
  }
}
