@import "src/styles/common";

.assessment-info-form {
  &__content {
    display: grid;
    width: 100%;
    margin-top: 52px;
    gap: 36px;

    @include mobile {
      margin-top: 36px;
      gap: 24px;
    }
  }

  &__notice-box {
    border: 1px solid $black100;
    border-radius: 12px;
    padding: 32px;
    display: grid;
    gap: 12px;
    text-align: center;
    @include set-body-1;
    white-space: pre-line;

    & > .txt-title { @include set-subtitle-1; }
    @include mobile {
      white-space: normal;
      & > .txt-title { @include set-subtitle-2; }
    }
  }

  &__input-wrap {
    display: grid;
    gap: 20px;
  }
}