@import "../../styles/common.scss";

.header {
  &__container {
    width: 100%;
    height: 106px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1100;
    @media (min-width: 1024px) {
      padding-left: 520px;
    }
  }

  &__content {
    display: flex;
   flex-direction: column;
   background-color: $white;
   width: 100%;
   max-width: 550px;
   position: relative;
   margin: 0 auto;
  }

  &__top-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $black100;
    padding: 0 20px;
    width: 100%;
    height: 56px;
    margin: 0 auto;
    @include set-subtitle-2;
  }

  &__bottom-area {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include set-subtitle-2;
  }

  &__menu {
    &__ul {
      display: flex;
      align-items: center;
      gap: 50px;
      padding: 0 10px;
      & > li {
        & > a {
          color: $black500;
          &:hover { color: $primary500; }
          &.selected { color: $primary500; }
        }
      }
    }
  }

  &__login-wrap {
    & > a {
      color: $black500;
      &.primary { color: $primary500; }
    }
    & > button { display: flex; }
  }

  &__sidebar-btn {
    display: block;
    & > button { @include set-subtitle-2;}
  }
}
