@import "src/styles/common";

.modal {
  &__wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include flex-center;
    z-index: 1;
  }

  &__container {
    @include flex-column;
    background-color: $white;
    border-radius: 20px;
    box-shadow: 0px 24px 40px 0px rgba(0, 0, 0, 0.12);
    width: 100%;
    max-width: 400px;

    @include mobile { max-width: 280px; }
  }

  &__icon {
    display: flex;
    justify-content: flex-end;
    padding: 20px 24px;
  }

  &__content {
    @include set-title-2;
    text-align: center;
    white-space: pre-line;
    padding: 0 24px;
  }

  &__btn-wrap {
    text-align: center;
    margin: 0 auto;
    padding: 24px 10px;
  }
}
