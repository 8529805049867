@import "src/styles/common";

.sidebar {
  &__wrap {
    position: fixed;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    transform: translateX(100%);
    opacity: 0;

    &.open {
      width: 100%;
      height: 100%;
      z-index: 998;

      transform: translateX(0);
      opacity: 1;
    }
  }

  &__container {
    position: absolute;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    z-index: 999;
    box-shadow: -8px 0px 16px 0px rgba(0, 0, 0, 0.08);
    background-color: $white;
    padding: 40px 34px 100px 24px;

    @include tablet {
      padding: 40px 0 100px 0;
    }
    // 웹 화면에서는 사이드바를 숨김
    @media (min-width: 769px) {
        display: none;
    }
  }

  &__content {
    @include flex-column;
    gap: 40px;
  }

  &__icon {
    display: flex;
    justify-content: flex-end;
    padding: 14px 24px;
  }

  &__menu {
    @include set-subtitle-2;
    color: $black500;

    & > ul {
      @include flex-column;
      gap: 4px;

      & > li {
        padding: 10px 24px;
        & > a {
          color: $black500;
          &:hover { color: $primary500; }
          &.selected { color: $primary500; }
        }
        &.__logout {
          color: $black200;
        }
      }
    }

    .has {
      & > a {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    &__depth-1 {
      @include set-subtitle-3;
      background-color: $black50;

      max-height: 0px; /* 초기 상태에서 숨김 */
      overflow: hidden; /* 내용이 넘치면 숨기기 */
      transition: max-height 0.3s ease; /* max-height에 transition 적용 */

      &.on {
        max-height: 400px; /* 열린 상태에서 최대 높이 지정 */
      }

      & > ul {
        @include flex-column;
        gap: 4px;
        & > li {
          @include flex-column;
          gap: 4px;
          & > a {
            color: $black500;
            &:hover, &.selected {
              color: $primary500;
            }
          }
        }
      }
      p {
        padding: 10px 24px;
        & > a {
          color: $black500;
          &:hover {
            color: $primary500;
          }
        }
      }
    }
    &__depth-2 {
      @include set-body-2;
      color: $black300;

      max-height: 0px; /* 초기 상태에서 숨김 */
      overflow: hidden; /* 내용이 넘치면 숨기기 */
      transition: max-height 0.3s ease; /* max-height에 transition 적용 */

      &.on {
        max-height: 400px; /* 열린 상태에서 최대 높이 지정 */
      }

      & > ul {
        @include flex-column;
        gap: 4px;

        & > li {
          padding: 8px 24px;
          & > a {
            color: $black300;
            &:hover, &.selected {
              color: $primary300;
            }
          }
        }
      }
    }
  }
}
