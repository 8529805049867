@import "src/styles/common";

.signup-form {
  &__container {
    @include flex-column;
    gap: 32px;
    margin-top: 48px;
    width: 100%;
  }

  &__content {
    @include flex-column;
    gap: 20px;
  }
}

.input-item {
  display: grid;
  grid-template-columns: 0.3fr 0.9fr;
  align-items: center;

  & > label {
    @include set-subtitle-1;
    color: $black500;

    &::after {
      content: "*";
      color: $primary500;
    }
  }
}

.input-button-wrap {
  display: flex;
  gap: 10px;
}
