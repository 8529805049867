@import "src/styles/common";

.modify-password-form {
  &__container {
    @include flex-column;
    gap: 36px;
    margin-top: 56px;
    width: 100%;
  }

  &__content {
    @include flex-column;
    gap: 20px;
  }
}
