@import "src/styles/common";

.completion {
  &__container {
    @include flex-column-vertical-center;
    gap: 42px;
    margin-top: 56px;
    width: 100%;
  }

  &__content {
    @include flex-column-vertical-center;
    gap: 24px;
  }

  &__btn-wrap {
    display: flex;
    justify-content: center;
    gap: 13px;
    margin-top: 40px;
    padding: 0 119px;
    width: 100%;

    @include mobile {
      flex-direction: column;
      margin-top: 32px;
      padding: 0;
    }
  }
}

.completion-ico-check-bg {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: $primary100;
  @include flex-center;

  @include mobile {
    width: 80px;
    height: 80px;
    & > svg {
      width: 40px;
      height: 40px;
    }
  }
}

.txt-wrap {
  @include flex-column-vertical-center;
  gap: 8px;
}

.txt-main {
  @include set-title-1;
  color: $black500;
  @include mobile {
    @include set-title-2;
  }
}

.txt-sub {
  @include set-body-1;
  color: $black300;
}
