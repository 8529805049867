@import "src/styles/common";

.email-form {
  &__container {
    display: grid;
    grid-template-columns: 0.3fr 0.9fr;
    align-items: center;

    & > label {
      @include set-subtitle-1;
      color: $black500;
      @include mobile { @include set-subtitle-2; }
    }

    input {
      @include set-body-1;
      border: 1px solid $black100;
      border-radius: 8px;
      padding: 12px 16px;
      width: 100%;

      &::placeholder { @include set-body-2; }
    }
    @include mobile {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 8px;
    }
  }

  &__content {
    @include flex-column;
    gap: 8px;
    & > span { text-align: center; }
  }
  
  &__item-wrap {
    display: grid;
    grid-template-columns: 1fr 0.1fr 1fr;
    align-items: center;
    gap: 8px;
    width: 100%;

    .error { border: 1px solid $danger; }
  }

  &__input-wrap {
    position: relative;
    display: flex;
    align-items: center;

    & > button {
      position: absolute;
      right: 0;
    }
  }

  &__selectbox {
    flex-grow: 1;
    border: 1px solid $black100;
    border-radius: 8px;
    padding: 12px 16px;
  }
}