@import "src/styles/common";

.gender-form {
  &__container {
    display: grid;
    grid-template-columns: 0.3fr 0.9fr;
    align-items: center;

    & > label {
      @include set-subtitle-1;
      color: $black500;

      &.essential::after {
        content: "*";
        color: $primary500;
      }
      @include mobile { @include set-subtitle-2; }
    }

    @include mobile {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 8px;
    }
  }

  &__content {
    @include flex-column;
    gap: 8px;
    width: 100%;
  }

  &__btn-wrap {
    display: flex;
    gap: 10px;
    width: 100%;
  }
}
