@import "src/styles/common.scss";

.number-tabbox {
  &__container {
    display: flex;
    gap: 8px;
    margin-top: 40px;
    margin-left: 10px;
    margin-right: 10px;
  }

  &__item {
    @include flex-column;
    text-align: center;
    @include set-subtitle-3;
    color: $black200;
    cursor: default;

    &.selected {
      color: $primary500;
      font-weight: 600;
    }
  }

  &__line-wrap {
    @include flex-center;
    display: block;
    & > .line {
      width: 20px;
      border: 1px solid $black100;
    }
  }
}
