@import "src/styles/common";

.white-box {
  background-color: $white;
  .txt-sub { @include set-body-2; }
  .txt-gray { color: $black300; }

  padding: 28px 24px;
  h3 { @include set-title-2; }
  .txt-title { @include set-subtitle-3; }
  .txt-subTitle {
    @include set-body-2;
    white-space: pre;
  }
}

.main { 
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &__container {
    background-color: $black50;
    width: 100%;
    max-width: 550px;
    margin-top: 106px;
    padding: 40px 0;
  }

  &__content {
    display: flex;
      flex-direction: column;
      padding: 0 20px;
  }

  &__lnb {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    &__title-wrap {
      display: grid;
      gap: 16px;

      & > h1 {
        @include set-headline-2;
        & span { color: $primary500; }
      }
    }

    &__explain {
      border-radius: 24px;
      padding-bottom: 0;
      height: 300px;
      position: relative;
    }

    &__img-wrap {
      position: absolute;
      bottom: -3px;
      & > svg {
        width: 140px;
        height: 154px;
      }
    }

    &__link-wrap {
      display: flex;
      flex-direction: initial;
      gap: 8px;
    }

    &__banner { 
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 24px;
      padding: 16px 20px;
      border-radius: 16px;
      width: 100%;
      cursor: pointer;
    }

    &__link {
      display: flex;
      align-items: center;
      gap: 8px;
      & + .icon-wrap { display: none; }
      & > svg {
        width: 28px;
        height: 28px;
      }
    }
    &__link-text {
      display: grid;
      gap: 2px;
      & > .txt-sub { display: none; }
    }
  }

  &__rnb {
    @include flex-column;
    gap: 20px;
    max-width: 820px;
    width: 100%;

    &__content-wrap {
      display: grid;
      gap: 24px;
    }

    &__banner {
      border-radius: 24px;
      position: relative;
      display: grid;
      width: 100%;

      &.col {
        grid-template-columns: 1fr 0.3fr;
        gap: 24px;

          display: flex;
          flex-direction: column;
          align-items: normal;
      }

      &.right-wrap { gap: 36px; }
      &.left-wrap {
        gap: 16px;
        padding-bottom: 8px;
      }

      &__item-wrap {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          gap: 12px;
          padding: 0 16px;
      }
      &__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;

        & > .img-box {
          border: 1px solid $black100;
          border-radius: 12px;
          background-color: $white;
          padding: 8px;
          & > svg {
            width: 40px;
            height: 40px;
          }
        }
        & > span {
          @include set-body-2;
          white-space: pre;
        }
      }
    }

    &__banner-both {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    &__txt-wrap {
      display: grid;
      gap: 12px;
    }

    &__img-wrap {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      @include mobile {
        & > svg {
          width: 183px;
          height: 150px;
        }
      }
    }

    &__btn-wrap {
      width: 70%;
      @include tablet { width: 50%; }
    }
  }
}

