@import "src/styles/common";

.assessment {
  &__container {
    @include flex-column-vertical-center;
    margin: 0 auto;
    margin-top: 80px;
    width: 610px;

    @include tablet {
      width: 100%;
      padding: 0 20px;
    }
    @include mobile { margin-top: 40px; }
  }

  &__title-wrap {
    @include flex-column;
    gap: 24px;
    width: 100%;
    margin: 0 auto;
    text-align: center;

    & > .primary { color: $primary500; }
    & > .sub-title { @include set-headline-2; }

    @include mobile {
      gap: 12px;
      & > .sub-title { @include set-subtitle-1; }
    }
  }

  &__content {
    width: 100%;
    margin-top: 100px;
    display: grid;
    gap: 80px;

    @include mobile {
      gap: 32px;
      margin-top: 36px;
    }
  }

  &__btn-wrap {
    display: flex;
    gap: 12px;
  }
}