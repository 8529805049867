@import "src/styles/common";

.kakao-login {
    &__container {
        @include common-btn-style;
        @include btn-xl;
        background-color: $kakao;
        color: $black500;
        &:hover {
            background-color: $kakao;
            color: $black500;
        }
        & > a {
            @include flex-center;
            text-decoration: none;
            height: 100%;
            color:  $black500;
        }
    }
}