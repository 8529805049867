@import "src/styles/common";

.text-input {
  @include set-body-1;
  border: 1px solid $black100;
  border-radius: 8px;
  padding: 12px 16px;
  width: 100%;

  &::placeholder {
    @include set-body-2;
    color: $black200;
  }

  &.error { border: 1px solid $danger; }

  @include mobile { @include set-body-2; }
}