@import "src/styles/common";
.privacy-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h2 {
    color: #047516;
    font-size: 35px;
    font-weight: bold;
  }

  & > .check-container {
    padding: 22px 0;
    margin-top: 42px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $main_color;
    border-radius: 30px;
    box-shadow: 0 4px 4px rgba(#000000, 0.25);
    width: 527px;
    height: 315px;

    & > div {
      padding: 6px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      min-width: 261px;

      & + div {
        margin-top: 5px;
      }

      & > .check-box {
        border-radius: 50%;
        border: solid 1px black;
        background-color: white;
        width: 30px;
        height: 30px;
        padding: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &.checked {
          background-color: #fa4c4c;
          &::before {
            content: "";
            width: 10px;
            height: 10px;
            background-image: url("../../../assets/images/cheked.png");
            background-repeat: no-repeat;
            background-size: contain;
          }
        }
      }
      .desc {
        margin-left: 20px;
        color: #19563c;
        font-size: 13px;
        font-weight: bold;
      }

      .detail {
        margin-left: 21px;
        color: #9a92f4;
        font-size: 11px;
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    @include mobile {
      width: calc(100vw - 40px);
    }

    .popup-container {
      display: grid;
      width: 300px;
      height: 300px;
      background-color: $main_color;
      position: absolute;
      border-radius: 15px;
      box-shadow: 0 4px 4px rgba(#000000, 0.25);
      padding: 30px;
      grid-template-rows: 90% 1fr;

      & > .info-desc {
        width: 100%;
        height: 100%;
        white-space: pre-line;
        line-height: 1.2;
        overflow-y: auto;
        overflow-x: hidden;
        color: #19563c;
        font-size: 13px;
        font-weight: bold;
      }

      & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 10px;
        & > button {
          width: 50%;
          background-color: #1c4532;
          border-radius: 15px;
          color: #f7fafc;
          cursor: pointer;
        }
      }
    }
  }

  & > button {
    margin-top: 26px;
    width: 527px;
    height: 58px;
    box-shadow: 0 4px 4px rgba(#000000, 0.25);
    color: #000000;
    border-radius: 15px;
    font-size: 24px;
    font-weight: bold;
    background-color: $main_color;
    border: none;
    cursor: pointer;

    &:disabled {
      cursor: auto;
      background-color: #a39c9c;
    }
    @include mobile {
      width: calc(100vw - 40px);
    }
  }
}
