@import "src/styles/common";

.new-signup {
  &__container {
    @include flex-column-vertical-center;
    gap: 20px;
    width: 100%;
    margin: auto;
    margin-top: 40px;
    padding: 0 20px;

    & > h2 { @include set-title-1; }
  }

  &__title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-direction: column;
    border-bottom: 2px solid $black500;
    margin-top: 48px;
    padding-bottom: 12px;
    gap: 4px;
    width: 100%;

    & > h3 { @include set-title-2; }
    & > p::before {
      content: "*";
      color: $primary500;
    }
  }
}
