@import "src/styles/common";

.care-grade {
  &__container {
    @include flex-column-vertical-center;
    margin: 0 auto;
    margin-top: 80px;
    padding: 0 20px;
    width: 550px;

    @include mobile {
      width: 100%;
      margin-top: 40px;
    }
  }

  &__content {
    @include flex-column-horizontal-center;
    gap: 36px;
    margin-top: 56px;

    &__text-box {
      @include set-body-1;
      border: 1px solid $black100;
      border-radius: 12px;
      padding: 32px;
      @include flex-column;
      text-align: center;
      gap: 12px;

      & > p {
        white-space: pre-line;
        &.last {
          @include set-body-2;
          color: $black300;
        }
      }
    }
    &__btn-wrap {
      text-align: center;
      margin: 0 auto;
      width: 65%;
    }

    @include mobile {
      margin-top: 36px;
      &__text-box {
        & > p { white-space: normal; }
      }
      &__btn-wrap { width: 100%; }
    }
  }

  &__img-wrap {
    margin-top: 40px;

    @include mobile {
      margin-top: 52px;
      & > svg {
        width: 264px;
        height: 264px;
      }
    }
  }
}