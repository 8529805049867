@import "src/styles/common";

.mypage-check-user-info {
  &__item-wrap {
    @include set-subtitle-1;
    @include flex-column;
    gap: 20px;

    @include mobile { @include set-subtitle-2; }
  }

  &__item {
    display: grid;
    grid-template-columns: 0.35fr 1fr;
    gap: 24px;
    width: 100%;
  }
}
