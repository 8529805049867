/**
	Screen Type
 */
@mixin desktop {
  @media screen and (max-width: 1024px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 550px) {
    @content;
  }
}

/**
	Typo Style - size
 */
@mixin set-headline-1 {
	font-size: 36px;
	font-weight: 600;
	line-height: 44px;
  letter-spacing: -0.02em;
}

@mixin set-headline-2 {
	font-size: 32px;
	font-weight: 600;
	line-height: 40px;
  letter-spacing: -0.02em;
}

@mixin set-title-1 {
	font-size: 24px;
	font-weight: 600;
	line-height: 32px;
  letter-spacing: -0.02em;
}

@mixin set-title-2 {
	font-size: 20px;
	font-weight: 600;
	line-height: 28px;
  letter-spacing: -0.02em;
}

@mixin set-subtitle-1 {
	font-size: 18px;
	font-weight: 600;
	line-height: 26px;
  letter-spacing: -0.02em;
}

@mixin set-subtitle-2 {
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
  letter-spacing: -0.02em;
}
@mixin set-subtitle-3 {
	font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.02em;
}

@mixin set-body-1 {
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
  letter-spacing: -0.02em;
}

@mixin set-body-2 {
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
  letter-spacing: -0.02em;
}

/**
	Typo Style - color
 */
@mixin txt-primary {
  color: $primary500;
}

@mixin txt-danger {
  color: $danger;
}

/**
	Button Style
 */
@mixin common-btn-style {
  border: none;
  border-radius: 8px;
  @include set-subtitle-2;
  background-color: $primary500;
  color: $white;
  width: 100%;

  &:hover {
    background-color: $primary600;
  }
  &:disabled {
    background-color: $black50;
    color: $black200;
    cursor: default;
  }
}

@mixin stroke-btn-style($borderColor, $color: $black500) {
  border: 1px solid $borderColor;
  border-radius: 8px;
  background-color: $white;
  color: $color;
  width: 100%;
  white-space: pre;
  @include set-subtitle-2;
  @include flex-center;

  &:disabled {
    border: 1px solid $black100;
    color: $black100;
    cursor: default;
  }
}

@mixin btn-xs {
  padding: 6px 12px;
  height: 32px;
}

@mixin btn-sm {
  padding: 6px 12px;
  height: 36px;
}

@mixin btn-md {
  padding: 10px 16px;
  height: 44px;
}

@mixin btn-lg {
  padding: 12px 16px;
  height: 48px;
}

@mixin btn-xl {
  padding: 16px 20px;
  height: 56px;
}

@mixin stroke-btn-gray {
  @include stroke-btn-style($black100);
}

@mixin stroke-btn-black {
  @include stroke-btn-style($black500);
}

@mixin storke-btn-primary {
  @include stroke-btn-style($primary500, $primary500);
}

/**
	Sort Style
 */
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-column-vertical-center {
  @include flex-column;
  align-items: center;
}

@mixin flex-column-horizontal-center {
  @include flex-column;
  justify-content: center;
}