@import "src/styles/common";

.search-id-form {
  &__container {
    @include flex-column;
    width: 100%;
    gap: 40px;
    margin-top: 56px;
  }

  &__input-wrap {
    @include flex-column;
    gap: 20px;
  }
}
