@import "src/styles/common";

.search {
  &__container {
    @include flex-column-vertical-center;
    width: 100%;
    padding: 0 20px;
    margin: auto;
    margin-top: 40px;
  }
}
