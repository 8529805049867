@import "src/styles/common";

.timer-input {
  &__container {
    display: grid;
    gap: 12px;

    &.row {
      grid-template-columns: 0.3fr 0.9fr;
      align-items: center;
      gap: 0;
    }
    & > label {
      @include set-subtitle-1;
      color: $black500;

      &.essential::after {
        content: "*";
        color: $primary500;
      }
      @include mobile { @include set-subtitle-2; }
    }

    @include mobile {
      display: flex;
      flex-direction: column;
      &.row {
        gap: 8px;
        align-items: start;
      }
    }
  }

  &__content {
    @include flex-column;
    @include set-body-2;
    width: 100%;

    &.msg { gap: 8px; }
  }

  &__item-wrap {
    @include flex-column;
    gap: 8px;
    
    &.both {
      display: grid;
      grid-template-columns: 1fr 0.45fr;
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $black100;
    border-radius: 8px;
    width: 100%;
    @include set-body-2;
    position: relative;

    & > input {
      @include set-body-1;
      border: none;
      padding: 12px 16px;
      width: 100%;
      border-radius: 8px;

      @include mobile { @include set-body-2; }
    }
  }

  &__right_wrap {
    position: absolute;
    right: 16px;

    & > span { @include txt-danger; }
  }
}
