@import "src/styles/common";

.mypage-sidebar {
  &__container {
    @include flex-column;
    & > h3 {
      @include set-headline-2;

      @include tablet { @include set-title-1; }
    }
  }

  &__content {
    @include flex-column;
    margin-top: 40px;
    display: none;
  }

  &__menu {
    @include flex-column;
    gap: 8px;

    & > p { @include set-title-2; }

    &:first-child::after {
      content: "";
      border: 1px solid $black100;
      margin: 24px 0;
      display: flex;
    }
  }

  &__menu-ul {
    @include set-subtitle-2;
    @include flex-column;
    color: $black200;
    width: 190px;

    & > li {
      padding: 10px 0;
      cursor: pointer;

      &:hover { color: $primary500; }
      &.selected { color: $primary500; }
    }
  }
}
