@import "src/styles/common";

.mypage-change-pw {
  &__content {
    @include flex-column;
    gap: 36px;
    width: 610px;
    margin-top: 40px;

    @include tablet {
      width: 100%;
      gap: 32px;
    }
  }

  &__txt-box {
    width: 100%;
    border: 1px solid $black100;
    border-radius: 12px;
    padding: 32px;
    @include set-subtitle-2;
    
    & > ul {
      margin-top: 12px;

      & > li {
        @include set-body-1;
        color: $black300;
        position: relative;
        padding-left: 9px;
        white-space: pre-line;

        &:before {
          position: absolute;
          left: 0;
          display: inline-block;
          content: "•";
        }
      }
    }

    @include mobile {
      padding: 24px;
      & > ul > li { @include set-body-2; }
    }
  }
}
 