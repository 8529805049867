@import "pallete";

.headline-0 {
	font-size: 40px;
	font-weight: 600;
	line-height: 52px;
}

.title-2 {
	font-size: 24px;
	font-weight: 600;
	line-height: 32px;
}

.title-3 {
	font-size: 20px;
	font-weight: 600;
	line-height: 28px;
}

.subtitle-1 {
	font-size: 18px;
	font-weight: 600;
	line-height: 26px;
}

.subtitle-2 {
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
}

.body-md {
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
}

.body-sm {
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
}