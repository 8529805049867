@import "src/styles/common";

.message-container {
  & > .message-box {
    margin: 0 auto;
    width: 100%;
    min-height: 386px;
    background-color: $main_color;
    box-shadow: 0 4px 4px rgba(#000000, 0.25);
    border-radius: 30px;
    padding: 30px 25%;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: bold;

    @include mobile {
      padding: 5px;
      font-size: 14px;
    }

    & > div + div {
      margin-top: 40px;
      @include mobile {
        margin-top: 45px;
      }
    }

    & > div > p {
      text-align: center;
      line-height: 30px;
    }

    & > div > p + p {
      margin-top: 30px;

      @include mobile {
        padding: 0;
        margin-top: 10px;
      }
    }
  }

  .warning {
    color: $sub_color;
    font-size: 20px;
    margin: 35px auto;
    text-align: center;

    @include mobile {
      font-size: 15px;
      font-weight: bold;
    }
  }

  .confirm-button {
    width: 100%;
    height: 110px;
    border-radius: 50px;
    box-shadow: -10px 10px 4px rgba(#000000, 0.25);
    color: $title_color;
    border: none;
    background-color: $main_color;
    font-size: 32px;
    cursor: pointer;

    @include mobile {
      font-size: 20px;
      border-radius: 30px;
      height: 89px;
    }
  }
}
