@import "src/styles/common";

.common-btn {
  @include common-btn-style;
  /* button size */
  &.xs {
    @include btn-xs;
  }
  &.sm {
    @include btn-sm;
  }
  &.md {
    @include btn-md;
  }
  &.lg {
    @include btn-lg;
  }
  &.xl {
    @include btn-xl;
  }
}
