@import "src/styles/common";

.mypage {
  &__wrap {
    max-width: 1000px;
    width: 100%;
    margin: auto;
    margin-top: 40px;
    padding: 0 20px;
    & > h2 { display: none; }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 0;
  }
}
