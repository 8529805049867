@import "src/styles/common";

.assessment-form {
  &__item {
    display: grid;
    gap: 24px;
    
    & > p { @include set-title-1; }

    @include mobile {
      gap: 16px;
      & > p { @include set-title-2; }
    }
  }

  &__answer-box {
    display: grid;
    gap: 12px;
  }
}