@import "src/styles/common";

.terms {
  &__container {
    @include flex-column;
    gap: 36px;
    margin-top: 52px;
  }

  &__content {
    @include flex-column;
    gap: 28px;

    & > .full-line {
      border: 1px solid $black100;
      width: 100%;
    }
  }

  &__item {
    @include flex-column;
    gap: 16px;
  }
}

.terms-box {
  overflow: auto;
  max-height: 204px;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid $black100;
  @include set-body-2;
  color: gray;
  white-space: pre-line;

  &::-webkit-scrollbar {
    width: 20px;
  }
  &::-webkit-scrollbar-thumb {
    height: 42px;
    background: $black200;
    border-radius: 10px;
    border: 6px solid #fff;
  }
  &::-webkit-scrollbar-track {
    border-radius: 0 6px 6px 0;
    background-color: transparent;
  }
}
