@import "src/styles/common";

.welcome-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;

  & > .message-container {
    & > p {
      color: #19563c;
      font-weight: bold;
      font-size: 20px;

      @include mobile {
        padding: 0 20px;
        font-size: 15px;
      }

      & + p {
        margin-top: 24px;
      }

      &:nth-child(1) {
        font-size: 35px;

        @include mobile {
          font-size: 20px;
        }
        & > span {
          color: #e90b0b;
        }
      }
    }
  }

  & > .user-type-container {
    margin-top: 25px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > span {
      background-color: $main_color;
      min-width: 434px;
      min-height: 58px;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      font-weight: bold;
      box-shadow: 0 4px 4px rgba(#000000, 0.25);
      @include mobile {
        min-width: 200px;
        font-size: 20px;
      }
    }
    & > .button-container {
      display: flex;
      flex-direction: column;
      margin-top: 23px;
      width: 573px;
      height: 215px;
      justify-content: center;
      background-color: $main_color;
      box-shadow: 0 4px 4px rgba(#000000, 0.25);
      border-radius: 30px;
      padding: 0 20px;

      @include mobile {
        width: calc(100% - 40px);
      }

      & > hr {
        border: 0;
        width: calc(100% - 130px);
        height: 1px;
        background-color: #000;
        margin-left: 0;
        @include mobile {
          width: calc(100% - 80px);
        }
      }

      & > .button-box {
        display: grid;
        grid-template-columns: 1fr 110px;
        @include mobile {
          grid-template-columns: 1fr 80px;
        }

        & > p {
          font-size: 24px;
          font-weight: bold;
        }

        & > button {
          border-radius: 15px;
          cursor: pointer;
          width: 100%;
          height: 42px;
          font-size: 13px;
          font-weight: bold;
          background-color: #ff8585;
          border: none;
        }
      }
    }
  }
}
