@import "src/styles/common";

.search-id-container {
  @include flex-column-vertical-center;
  padding-top: 46px;
  width: 100%;
  & > p { @include set-title-1; }
}

.serach-id-box {
  display: flex;
  align-items: center;
  gap: 12px;
  border: 1px solid $black100;
  border-radius: 8px;
  padding: 32px 24px;
  margin-top: 24px;
  width: 100%;
}

.ico-check-bg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $primary100;
  @include flex-center;

  & > svg {
    width: 14px;
    height: 20px;
    color: $primary500;
  }
}

.search-id-box-txt {
  @include flex-column;
  gap: 2px;

  & > .primary-txt {
    @include set-title-2;
    color: $primary500;
  }
  & > .gray-txt {
    @include set-body-2;
    color: $black300;
  }
}

.search-id-btn-wrap {
  @include flex-column;
  gap: 12px;
  margin-top: 40px;
  width: 100%;
}
