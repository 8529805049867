@import "src/styles/common";

.app {
    &__container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 120px;
      width: 100%;
      height: 100vh;
      min-height: var(--100vh);
      margin: 0 auto;
      box-sizing: border-box;
      background-color: $black50;
    }
    
    &__main-container {
        width: 100%;
    }
  
    &__left-side {
      width: 400px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      height: var(--100vh);
  
      @include  desktop { display: none; }
    }
    &__right-side {
        display: flex;
        box-sizing: border-box;
        box-shadow: rgba(50, 50, 50, 0.12) 0px 0px 16px;
        max-width: 550px !important;
        width: 100%;
        height: var(--100vh);
        background-color: $white;
    }

    &__bottom-bar {
        position: fixed;
        width: 100%;
        height: 10px;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1100;
    }
}