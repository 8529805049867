@import "src/styles/common";

.questionnaire-form-container {
  padding-top: 100px;
  @include mobile {
    padding-top: 50px;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    background-color: $main_color;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.3);
    border-radius: 30px;

    & > .form-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      & + .form-section {
        margin-top: 250px;

        @include mobile {
          margin-top: 100px;
        }
      }

      &.genderSection {
        margin-top: 0;
      }

      & > .section-title {
        font-size: 32px;
        font-weight: 800;
        text-align: center;
        margin-bottom: 50px;
        color: $title_color;

        @include mobile {
          font-size: 16px;
        }
      }

      & > .section_description {
        width: calc(100% - 260px);

        & > p:nth-child(1) {
          font-size: 34px;
          color: $sub_title_color;
          margin-bottom: 50px;
          font-weight: 500;
        }
        & > p:last-child {
          font-size: 17px;
          font-weight: 700;
          color: $sub_title_color;
          border-bottom: solid #000000;
          padding-bottom: 10px;
        }

        @include mobile {
          display: none;
        }
      }

      .question-container {
        display: flex;
        flex-direction: column;
        margin-top: 50px;
        width: 100%;
        align-items: center;
        & > input[type="text"] {
          border: 2px solid #19563c;
        }

        &.name {
          flex-direction: row;
          justify-content: center;
          @include mobile {
            flex-direction: column;
          }
        }

        & + .question-container {
          margin-top: 50px;

          @include mobile {
            margin-top: 64px;
          }
        }

        & > .question-message {
          color: $main_color2;
          font-size: 23px;
          font-weight: 700;

          @include mobile {
            font-size: 20px;
            line-height: 40px;
            text-align: center;
          }

          &.name {
            margin-right: 10px;
          }
        }

        & > .answer-container {
          display: flex;
          flex-direction: row;
          width: 683px;
          margin-top: 20px;
          justify-content: space-evenly;

          @include mobile {
            width: 266px;
          }

          & > .answer-box {
            width: 210px;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            @include mobile {
              width: 76px;
            }

            & > .answer-message {
              font-size: 18px;
              font-weight: 800;

              @include mobile {
                font-size: 12px;
              }
            }

            & > input[type="radio"] {
              margin-top: 20px;
              appearance: none;
              width: 64px;
              height: 64px;
              border: 1px solid black;
              border-radius: 50%;
              cursor: pointer;

              @include mobile {
                width: 41px;
                height: 41px;
              }
              &:checked {
                background-color: #fa4c4c;
                background-repeat: no-repeat;
              }
            }
          }
        }
      }
    }
  }

  .confirm-button {
    margin-top: 110px;
    width: 100%;
    height: 110px;
    border-radius: 50px;
    box-shadow: -10px 10px 4px rgba(#000000, 0.25);
    color: $title_color;
    border: none;
    background-color: $main_color;
    font-size: 40px;
    font-weight: 700;
    cursor: pointer;

    &:disabled {
      cursor: auto;
      background-color: #a39c9c;
    }

    @include mobile {
      border-radius: 30px;
      font-size: 20px;
      height: 84px;
    }
  }
}
