@import "src/styles/common.scss";

.line-tabbox {
  &__container {
    display: flex;
    width: 100%;
    margin-top: 40px;
  }

  &__item {
    border-bottom: 1px solid $black100;
    color: $black300;
    padding: 16px;
    @include set-body-1;
    width: 100%;
    text-align: center;
    cursor: pointer;

    &.selected {
      @include set-subtitle-2;
      color: $primary500;
      border-bottom: 2px solid $primary500;
    }
  }

  @include mobile {
    margin-top: 16px;
  }
}
