@import "reset";
@import "variables";
@import "mixin";
@import "pallete";
@import "typo";

@font-face {
  src: url("../assets/fonts/NotoSansKR-Regular.ttf");
  font-weight: 400;
  font-family: "notoSans-regular";
}

@font-face {
  src: url("../assets/fonts/NotoSansKR-Medium.ttf");
  font-weight: 500;
  font-family: "notoSans-medium";
}

@font-face {
  src: url("../assets/fonts/NotoSansKR-SemiBold.ttf");
  font-weight: 600;
  font-family: "notoSans-semiBold";
}

@font-face {
  src: url("../assets/fonts/NotoSansKR-Bold.ttf");
  font-weight: 700;
  font-family: "notoSans-bold";
}

@font-face {
  src: url("../assets/fonts/NotoSansKR-ExtraBold.ttf");
  font-weight: 800;
  font-family: "notoSans-extraBold";
}

@font-face {
  src: url("../assets/fonts/NotoSansKR-Black.ttf");
  font-weight: 900;
  font-family: "notoSans-black";
}

* {
  font-family: notoSans-regular, sans-serif;
  box-sizing: border-box;
}

.ft-regular {
  font-family: notoSans-regular, sans-serif;
}

.ft-medium {
  font-family: notoSans-medium, sans-serif;
}

.ft-semiBold {
  font-family: notoSans-semiBold, sans-serif;
}

.ft-bold {
  font-family: notoSans-bold, sans-serif;
}

.ft-extraBold {
  font-family: notoSans-extraBold, sans-serif;
}

.app-main-container {
  // min-height: 100vh;
  // overflow-x: hidden;
}

.page-main-container {
  height: 100%;
  padding-bottom: 40px;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  padding-top: 106px;
  padding-bottom: 40px;
}

h2 {
  @include set-headline-1;

  @include tablet { @include set-headline-2; }
  @include mobile { @include set-title-1; }
}