@import "src/styles/common";

$sizes: xs, sm, md, lg, xl;

.stroke-btn {
  /* button size */
  &.xs {
    @include btn-xs;
  }
  &.sm {
    @include btn-sm;
  }
  &.md {
    @include btn-md;
  }
  &.lg {
    @include btn-lg;
  }
  &.xl {
    @include btn-xl;
  }

  /* button stroke color */
  @each $size in xs, sm, md, lg, xl {
    &.#{$size}.gray {
      @include stroke-btn-gray;
    }
    &.#{$size}.black {
      @include stroke-btn-black;
    }
    &.#{$size}.primary {
      @include storke-btn-primary;
    }
  }
}
