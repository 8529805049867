@import "src/styles/common";

.radio-btn-wrap {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  cursor: pointer;

  label {
    @include stroke-btn-gray;
    @include btn-lg;
  }

  input:disabled {
    cursor: initial;
    & + label {
      background-color: rgba(239, 239, 239, 0.3);
      color: rgb(84, 84, 84);
    }
  }

  input {
    appearance: none;
    display: none;

    &:checked + label {
      border-color: $primary500;
      color: $primary500;
    }
  }
}
