/**
    Primary Color
 */
$primary100: #E7FCE1;
$primary200: #ADD79F;
$primary300: #84C370;
$primary400: #5BAF40;
$primary500: #329B10;
$primary600: #287C0D;

/**
    Black Color
 */
$black50: #F1F1F1;
$black100: #D1D1D1;
$black200: #A3A3A3;
$black300: #757575;
$black500: #191919;

/**
	B&W Color
 */
 $white: #FFFFFF;

/**
    System Color
 */
$danger: #DF1D1D;

/**
    Kakao Color
 */
 $kakao: #FEE500;