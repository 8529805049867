@import "src/styles/common";

.care-grade-result {
  &__bg {
    width: 100%;
    height: 549px;
    border-bottom: 5px solid $black500;
    position: relative;
    &__rect {
      width: 100%;
      height: 100%;
      background-color: $primary500;
    }
    &__img-wrap {
      position: absolute;
      bottom: -39px;
      width: 100%;
      text-align: center;

    }
    @include mobile {
      height: 390px;
      border-bottom: 4px solid $black500;
      &__img-wrap {
        bottom: -71px;
        & > svg { width: 250px; }
      }
    }
  }

  &__title-wrap {
    color: $white;
    position: absolute;
    width: 100%;
    text-align: center;
    margin-top: 80px;
    @include mobile { margin-top: 40px; }
  }
  &__title {
    @include flex-column-vertical-center;
    gap: 12px;
    @include mobile {
      & > h2 { @include set-headline-2; }
    }
  }

  &__grade-wrap {
    @include flex-column-vertical-center;
    gap: 16px;
    margin-top: 32px;
    & > p { @include set-title-1; }
    & > .grade-box {
      @include set-headline-1;
      background-color: white;
      color: $primary500;
      border-radius: 12px;
      padding: 16px 28px;
    }
    
    @include mobile {
      margin-top: 24px;
      & > p { @include set-title-2; }
      & > .grade-box {
        @include set-headline-2;
        padding: 14px 24px;
      }
    }
  }

  &__content {
    @include flex-column-vertical-center;
    margin: 0 auto;
    margin-top: 78px;
    width: 610px;

    @include tablet {
      width: 100%;
      padding: 0 20px;
    }
    @include mobile { margin-top: 40px; }

    &__score {
      @include set-title-2;
      background-color: $black50;
      border-radius: 8px;
      padding: 12px 20px;
      text-align: center;
      & > span { color: $primary500; }

      @include mobile { @include set-subtitle-1; }
    }

    &__desc {
      @include set-title-2;
      @include flex-column;
      gap: 24px;
      border: 1px solid $black100;
      border-radius: 12px;
      padding: 32px;
      margin-top: 24px;
      width: 100%;

      @include mobile { @include set-subtitle-1; }

      & > .desc-text {
          @include flex-column;
          gap: 8px;
      }
      .txt-gray {
        @include set-body-1;
        color: $black300;
        & > span {
          @include set-subtitle-2;
          color: $primary500;
        }
      }
      .caution {
        & > p { @include set-body-2; }
        &::before {
          content: "";
          border: 1px solid $black100;
          margin-bottom: 24px;
          display: flex;
        }
      }
    }
      
    &__recommand {
      @include flex-column-vertical-center;
      gap: 24px;
      margin: 80px 0;
      width: 100%;

      & > h3 { @include set-headline-2; }

      &__box {
        background-color: $black50;
        border-radius: 12px;
        padding: 32px;
        width: 100%;
      }
      &__item-wrap {
        @include flex-center;
        gap: 24px;
      }  
      &__item {
        @include set-title-2;
        @include flex-column-vertical-center;
        gap: 20px;
        width: 100%;
      }

      @include mobile {
        width: 100%;
        margin: 32px;
        gap: 16px;

        & > h3 { @include set-title-1; }
        &__box { padding: 24px 32px; }
        &__item-wrap { flex-direction: column; }
        &__item { width: auto; }
      }
    }
  }

  &__btn-wrap {
    width: 55%;
    text-align: center;

    @include mobile { width: 100%; }
  }
}
